<script setup>
import {
  ref,
  onMounted,
  watch,
  onBeforeUnmount,
  watchEffect,
  onUnmounted,
} from "vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";
import MainAutoMessage from "/src/components/molecules/MainAutoMessage.vue";
import GoodsAutoMessage from "/src/components/molecules/GoodsAutoMessage.vue";
import TrecaAutoMessage from "/src/components/molecules/TrecaAutoMessage.vue";
import MainRanking from "/src/components/molecules/MainRanking.vue";
import db from "../main.js";
import {
  doc,
  onSnapshot,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
  Timestamp,
} from "firebase/firestore";
import { useRouter, useRoute } from "vue-router";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "@videojs/http-streaming";

const videoPlayer = ref(null);
let player = null;

const auth = getAuth();
const uid = ref();

const srturl = ref();
const livedatetime = ref();
const livedatetimeshow = ref();
const livedatetimeend = ref();
const livedatetimeshowend = ref();

const streamkey = ref();
const campaign = ref();
const liveSchedules = ref([]);
const vendorja = ref();
const eventtitle = ref();
const rankingarray = ref(0);

const route = useRoute();
const livenumber = ref(route.query.livenumber);
const livenumberja = ref();
if (livenumber.value == "first") {
  livenumberja.value = "第1回配信";
}
if (livenumber.value == "second") {
  livenumberja.value = "第2回配信";
}
if (livenumber.value == "third") {
  livenumberja.value = "第3回配信";
}
if (livenumber.value == "fourth") {
  livenumberja.value = "第4回配信";
}
if (livenumber.value == "fifth") {
  livenumberja.value = "第5回配信";
}

const licestarttime = ref();
let rankingfirstusername = ref(null);
let rankingsecondusername = ref(null);
let rankingthirdusername = ref(null);
let rankingfirsticonURL = ref(null);
let rankingsecondiconURL = ref(null);
let rankingthirdiconURL = ref(null);

const commenttext = ref(null);
const videouid = ref(null);
onMounted(() => {
  onAuthStateChanged(auth, (userdata) => {
    if (userdata) {
      uid.value = userdata.uid;

      getCurrenteventid()
        .then(async (currenteventid) => {
          console.log(currenteventid);
          const docRef = doc(
            db,
            "Goods_products",
            currenteventid,
            "streaminfo",
            "streaminfo"
          );
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            srturl.value = docSnap.data().srt;
            streamkey.value = docSnap.data().streamkey;
            campaign.value = docSnap.data().campaign;

            const q = query(
              collection(db, "Goods_liveshedule"),
              where("event_id", "==", currenteventid)
            );

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              liveSchedules.value.push(doc.data());
              console.log(liveSchedules.value);
              getlivedatetimefun();
            });

            const docRefevent = doc(db, "Goods_products", currenteventid);
            const docSnapevent = await getDoc(docRefevent);

            if (docSnapevent.exists()) {
              console.log("Document data:", docSnapevent.data());
              vendorja.value = docSnapevent.data().vendor_ja;
              eventtitle.value = docSnapevent.data().eventtitle;

              // videouid の変更を監視し、ビデオURLを設定
              watch(videouid, (newVideouid) => {
                if (newVideouid) {
                  videourl.value =
                    process.env.VUE_APP_CLOUDFLARE_SUBDOMAIN +
                    newVideouid +
                    "/manifest/video.m3u8";

                  // プレーヤーのソースを更新
                  player.src({
                    src: videourl.value,
                    type: "application/x-mpegURL",
                  });
                  player.play(); // ビデオの再生を開始
                }
              });

              onSnapshot(doc(db, "Goods_products", currenteventid), (doc) => {
                videouid.value = doc.data().videouid;
                console.log("Current data: ", doc.data());
              });
            } else {
              // docSnap.data() が undefined の場合
              console.log("No such document!");
            }
          } else {
            // docSnap.data() が undefined の場合
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.error("Error getting event id:", error);
        });
    } else {
      console.log("User is logged out");
    }
  });

  player = videojs(videoPlayer.value, {
    autoplay: true,
    controls: false,
    muted: true,
  });

  // watchEffectでvideourlの変化を監視し、ソースを変更します
  watchEffect(() => {
    if (videourl.value) {
      player.src({ src: videourl.value, type: "application/x-mpegURL" });
    }
  });
  // videoUrlの値が変更されたときにビデオプレーヤを設定
  // const unwatch = watch(
  //   videourl,
  //   (newUrl, oldUrl) => {
  //     if (newUrl) {
  //       unwatch();
  //       setupPlayer(newUrl);
  //     }
  //   },
  //   { immediate: true }
  // );
});

onBeforeUnmount(() => {
  if (player) {
    player.dispose();
  }
});

const router = useRouter();
const ToKanri = (times) => {
  console.log(times);
  router.push(`/kanri?livenumber=${times}`);
};

const getCurrenteventid = async () => {
  try {
    const q = query(
      collection(db, "users", uid.value, "vendor_event"),
      orderBy("created_at", "desc"), // タイムスタンプで降順にソート
      limit(1) // 最新のもの1つだけ取得
    );

    const querySnapshot = await getDocs(q);

    // 最新のドキュメントを取得（orderByとlimitにより、1つのドキュメントのみ取得される）
    const latestEvent = querySnapshot.docs[0];

    if (latestEvent) {
      console.log("Latest event id: ", latestEvent.id);
      return latestEvent.id;
    } else {
      throw new Error("No event found");
    }
  } catch (error) {
    console.error("Error getting latest event id:", error);
    throw error;
  }
};

const getCurrenteventidforFetch = async () => {
  // UIDを取得する処理を追加
  await new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (userdata) => {
      if (userdata) {
        uid.value = userdata.uid;
        resolve();
      } else {
        reject(new Error("User not authenticated"));
      }
    });
  });

  try {
    const q = query(
      collection(db, "users", uid.value, "vendor_event"),
      orderBy("created_at", "desc"), // タイムスタンプで降順にソート
      limit(1) // 最新のもの1つだけ取得
    );

    const querySnapshot = await getDocs(q);

    // 最新のドキュメントを取得（orderByとlimitにより、1つのドキュメントのみ取得される）
    const latestEvent = querySnapshot.docs[0];

    if (latestEvent) {
      console.log("Latest event id: ", latestEvent.id);
      return latestEvent.id;
    } else {
      throw new Error("No event found");
    }
  } catch (error) {
    console.error("Error getting latest event id:", error);
    throw error;
  }
};

function getlivedatetimefun() {
  if (livenumber.value == "first") {
    const liveSchedule = liveSchedules.value.find(
      (schedule) => schedule.livenumber === "第1回配信"
    );
    if (liveSchedule) {
      livedatetime.value = liveSchedule.livedate.start;
      console.log(livedatetime.value);
      livedatetimeshow.value = new Date(
        livedatetime.value.seconds * 1000
      ).toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
      livedatetimeend.value = liveSchedule.livedate.end;
      console.log(livedatetimeend.value);
      livedatetimeshowend.value = new Date(
        livedatetimeend.value.seconds * 1000
      ).toLocaleString("ja-JP", {
        // year: "numeric",
        // month: "2-digit",
        // day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }
  if (livenumber.value == "second") {
    const liveSchedule = liveSchedules.value.find(
      (schedule) => schedule.livenumber === "第2回配信"
    );
    if (liveSchedule) {
      livedatetime.value = liveSchedule.livedate.start;
      console.log(livedatetime.value);
      livedatetimeshow.value = new Date(
        livedatetime.value.seconds * 1000
      ).toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }
  if (livenumber.value == "third") {
    const liveSchedule = liveSchedules.value.find(
      (schedule) => schedule.livenumber === "第3回配信"
    );
    if (liveSchedule) {
      livedatetime.value = liveSchedule.livedate.start;
      console.log(livedatetime.value);
      livedatetimeshow.value = new Date(
        livedatetime.value.seconds * 1000
      ).toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }

  if (livenumber.value == "fourth") {
    const liveSchedule = liveSchedules.value.find(
      (schedule) => schedule.livenumber === "第4回配信"
    );
    if (liveSchedule) {
      livedatetime.value = liveSchedule.livedate.start;
      console.log(livedatetime.value);
      livedatetimeshow.value = new Date(
        livedatetime.value.seconds * 1000
      ).toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }

  if (livenumber.value == "fifth") {
    const liveSchedule = liveSchedules.value.find(
      (schedule) => schedule.livenumber === "第5回配信"
    );
    if (liveSchedule) {
      livedatetime.value = liveSchedule.livedate.start;
      console.log(livedatetime.value);
      livedatetimeshow.value = new Date(
        livedatetime.value.seconds * 1000
      ).toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }
}

const playerOptions = ref({
  controls: false,
  // 他のオプション...
});

const playerReadied = () => {
  console.log("Video player is ready");
  // 必要なアクションをここに記述します
};

const videourl = ref(null);
// const getvideurl = async () => {
//   onSnapshot(doc(db, "Goods_products", "ciel-1"), (doc) => {
//     console.log("Current data: ", doc.data());
//     videourl.value =
//       process.env.VUE_APP_CLOUDFLARE_SUBDOMAIN +
//       doc.data().videouid +
//       "/manifest/video.m3u8";
//     console.log(videourl.value);
//     console.log(typeof videourl.value);
//   });
// };
// getvideurl();

function setupPlayer(url) {
  const player = videojs(videoPlayer.value, {
    sources: [{ src: url, type: "application/x-mpegURL" }],
    autoplay: true,
    controls: false,
    muted: true,
  });

  // if you need to release resources or destroy the player when component unmounted
  // import { onBeforeUnmount } from 'vue'
  // onBeforeUnmount(() => {
  //   if (player) {
  //     player.dispose()
  //   }
  // })
}

const showmessage = ref([]);
const showmessagegoods = ref([]);
const showmessagetreca = ref([]);

async function fetchData() {
  try {
    const currenteventid = await getCurrenteventidforFetch();

    // livedatetime.valueが設定されるのを監視
    watch(
      livedatetime,
      async (newValue) => {
        if (newValue) {
          console.log("時間取得: " + newValue);
          // newValueの10分前のタイムスタンプを計算
          const tenMinutesBefore = new Timestamp(
            newValue.seconds - 600,
            newValue.nanoseconds
          );
          console.log("10分前" + tenMinutesBefore);
          // Query for Goods_automessages
          const q = query(
            collection(
              db,
              "Goods_automessages",
              currenteventid,
              "automessages"
            ),
            orderBy("created_at", "asc"),
            where("created_at", ">=", tenMinutesBefore)
          );

          onSnapshot(q, (querySnapshot) => {
            const nameshot = [];
            querySnapshot.docChanges().forEach((change) => {
              if (change.type === "added") {
                const setmessage = change.doc.data();
                showmessage.value.push(setmessage);
                if (setmessage.commenttype == "goods") {
                  showmessagegoods.value.push(setmessage);
                }
                if (setmessage.commenttype == "treca") {
                  showmessagetreca.value.push(setmessage);
                }
              }
              if (change.type === "modified") {
                console.log("変更: ", change.doc.data());
              }
              if (change.type === "removed") {
                console.log("削除: ", change.doc.data());
              }
            });

            // Function for fetching points
            const getpoint = async () => {
              const querypoint = query(
                collection(db, "Goods_ranking", currenteventid, "ranking"),
                limit(3),
                orderBy("points", "desc")
              );

              onSnapshot(querypoint, (querySnapshot) => {
                rankingarray.value = [];
                if (querySnapshot.empty) {
                  console.log("No matching documents.");
                  return;
                }

                querySnapshot.forEach((doc) => {
                  const setranking = doc.data();
                  rankingarray.value.push(setranking);
                });
              });
            };

            getpoint();
          });
        }
      },
      { immediate: true }
    );

    watch(
      rankingarray,
      (newValue) => {
        if (newValue) {
          if (newValue[0]) {
            rankingfirstusername.value = newValue[0].name;
            rankingfirsticonURL.value = newValue[0].iconURL;
          }
          if (newValue[1]) {
            rankingsecondusername.value = newValue[1].name;
            rankingsecondiconURL.value = newValue[1].iconURL;
          }
          if (newValue[2]) {
            rankingthirdusername.value = newValue[2].name;
            rankingthirdiconURL.value = newValue[2].iconURL;
          }
        }
      },
      { immediate: true }
    );
  } catch (error) {
    console.error("Failed to fetch data:", error);
  }
}

fetchData();

const displayTime = ref("00:00");

let startTime;
let timerInterval;

const startTimer = () => {
  startTime = new Date();
  updateDisplay();
  timerInterval = setInterval(updateDisplay, 1000);
};

const stopTimer = () => {
  clearInterval(timerInterval); // タイマーを停止
  displayTime.value = "00:00"; // タイマーをリセット
};

const updateDisplay = () => {
  const now = new Date();
  const elapsedSeconds = Math.floor((now - startTime) / 1000);
  const minutes = String(Math.floor(elapsedSeconds / 60)).padStart(2, "0");
  const seconds = String(elapsedSeconds % 60).padStart(2, "0");
  displayTime.value = `${minutes}:${seconds}`;
};

const streamingflag = ref(false);
const VideoStart = async () => {
  startdialog.value = true;
  const functions = getFunctions();
  functions.region = "asia-northeast1";

  // ここでのイベントID取得等の処理
  const event_id = await getCurrenteventid();

  const getURL = httpsCallable(functions, "Goods_vendoradmin_geturl");
  getURL({
    event_id: event_id,
    streamkey: streamkey.value,
    livenumber: livenumberja.value,
    // 必要なパラメータ
  })
    .then((res) => {
      // 成功時の処理
      startTimer(); // タイマーのスタート
      streamingflag.value = true;
      console.log(streamingflag.value);
    })
    .catch((e) => {
      console.log(e);
    });
};

const VideoStop = async () => {
  enddialog.value = true;
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  // ここでのイベントID取得等の処理
  const event_id = await getCurrenteventid();

  const getURL = httpsCallable(functions, "Goods_vendoradmin_finishstreaming");
  getURL({
    event_id: event_id,
    livenumber: livenumberja.value,
    // 必要なパラメータ
  })
    .then((res) => {
      // 成功時の処理
      stopTimer(); // タイマーの停止
      streamingflag.value = false;
    })
    .catch((e) => {
      console.log(e);
    });
};

// 別ページに遷移するときに実行される
onBeforeUnmount(() => {
  stopTimer();
});

const searchAndUpdateLiveStatus = async (liveno, vendoren) => {
  const q = query(
    collection(db, "Goods_liveshedule"),
    where("vendor_en", "==", vendoren),
    where("livenumber", "==", liveno)
  );

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    console.log(`Found document: ${doc.id} => ${JSON.stringify(doc.data())}`);
    updateLiveStatus(doc.id);
  });
};

const updateLiveStatus = async (docId) => {
  const docRef = doc(db, "Goods_liveshedule", docId);
  await updateDoc(docRef, { live_status: "end" });
};

const onKeypressEnter = async () => {
  console.log(commenttext.value);
  await commentinput(commenttext.value);
  // await deletecommentcontainer();
};
//コメントを入力枠から削除する
const deletecommentcontainer = () => {
  commenttext.value = null;
};

//通常のコメントをデータベースへ書き込み
const commentinput = async (commenttext) => {
  console.log(commenttext);
  const event_id = await getCurrenteventid();
  console.log(event_id);
  console.log("function呼び出し");
  const functions = getFunctions();
  functions.region = "asia-northeast1";
  //refを消す

  const normalmessageNo = commenttext;
  const vendornameJaNo = vendorja.value;
  const myCallableFunction = httpsCallable(functions, "Goods_vendorcomment");
  myCallableFunction({
    normalmessage: normalmessageNo,
    event_id: event_id,
    vendornameJa: vendornameJaNo,
  })
    .then((result) => {
      console.log(result);
      deletecommentcontainer();
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
};

const copyUrl = async () => {
  try {
    await navigator.clipboard.writeText(srturl.value);
    console.log("コピー成功");
  } catch (err) {
    console.error("コピー失敗", err);
  }
};

const copyStreamkey = async () => {
  try {
    await navigator.clipboard.writeText(streamkey.value);
    console.log("コピー成功");
  } catch (err) {
    console.error("コピー失敗", err);
  }
};

const liveViewers = ref(0); // liveViewers を初期化します

const getLiveViewers = async () => {
  console.log(streamkey.value);
  const response = await axios.get(
    process.env.VUE_APP_CLOUDFLARE_SUBDOMAIN + `${streamkey.value}/views`
  );
  console.log(response);
  console.log(response.data.liveViewers);
  liveViewers.value = response.data.liveViewers;
};

let intervalId = null;

onMounted(() => {
  intervalId = setInterval(getLiveViewers, 5000); // 5秒ごとにgetLiveViewersを実行します
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId); // コンポーネントがアンマウントされた時に定期実行を止めます
  }
});

const movetoHome = () => {
  router.push("/");
};

const startdialog = ref(false);
const enddialog = ref(false);
</script>

<template>
  <html>
    <body>
      <div class="container">
        <v-dialog v-model="startdialog" max-width="600px">
          <v-card>
            <v-card-title>配信開始</v-card-title>
            <v-card-text
              >配信を開始します。配信が流れるまで最大30秒程度かかることがあります。</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="startdialog = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="enddialog" max-width="600px">
          <v-card>
            <v-card-title>配信終了</v-card-title>
            <v-card-text
              >配信を終了しました。別途配信ソフトの配信も停止してください。</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="enddialog = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- main -->
        <div class="main">
          <!-- control_set -->
          <div class="control_set">
            <div class="btn_back" @click="movetoHome()">
              <a class="btn">戻る</a>
            </div>
            <!-- movie配信 -->
            <div class="movie">
              <!-- <video autoplay muted playsinline loop>
                <source src="img/default.mp4" type="video/mp4" />
              </video> -->

              <div>
                <video
                  ref="videoPlayer"
                  class="video-js vjs-default-skin"
                ></video>
              </div>

              <!-- <video
                ref="videoPlayer"
                class="video-js vjs-default-skin"
              ></video> -->

              <!-- <div class="video-player-box">
                <video-player
                  class="vjs-custom-skin"
                  :options="playerOptions.value"
                  @ready="playerReadied"
                ></video-player>
              </div> -->

              <!-- 配信中情報 -->
              <div class="info">
                <!-- 同時接続 -->
                <div class="connections">
                  <p class="title">同時接続</p>
                  <p class="count">{{ liveViewers }}人</p>
                </div>
                <!-- 配信中情報 -->
                <div class="status">
                  <p class="title stop" v-if="!streamingflag">配信前</p>
                  <p class="title play" v-if="streamingflag">配信中</p>
                  <p class="time">{{ displayTime }}</p>
                </div>
              </div>
            </div>
            <!-- movie配信END -->
            <!-- control -->
            <div class="control">
              <!-- 基本情報 -->
              <div class="control_unit">
                <dl class="info_common">
                  <dt>配信者名</dt>
                  <dd>{{ vendorja }}</dd>
                  <dt>イベント名</dt>
                  <dd>{{ eventtitle }}</dd>
                  <dt>配信情報</dt>
                  <dd>
                    {{ livenumberja }}{{ livedatetimeshow }}〜{{
                      livedatetimeshowend
                    }}
                  </dd>
                </dl>
              </div>
              <!-- 配信情報 -->
              <div class="control_unit">
                <dl class="info_broadcast">
                  <dt>
                    配信サーバーURL<br /><a class="btn" @click="copyUrl"
                      >コピー</a
                    >
                  </dt>
                  <dd>
                    <span class="urlstreaminfo">{{ srturl }}</span>
                  </dd>
                  <dt>
                    ストリームキー<br /><a class="btn" @click="copyStreamkey()"
                      >コピー</a
                    >
                  </dt>
                  <dd>{{ streamkey }}</dd>
                </dl>
              </div>
              <!-- ボタン動画コントロール -->
              <div class="control_unit">
                <ul class="btn_set">
                  <li>
                    <p>
                      <a class="movie_play" @click="VideoStart()"
                        >配信を開始する</a
                      ><br />配信ソフトの配信を<br />開始してから押してください
                    </p>
                  </li>
                  <li>
                    <p>
                      <a class="movie_stop" @click="VideoStop()"
                        >配信を終了する</a
                      ><br />別途配信ソフトの<br />配信も停止してください
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- control END -->
          </div>
          <!-- control_set END -->

          <!-- timeline_set タイムライン各種  -->
          <div class="timeline_set">
            <!-- タイムライン_グッズ  -->
            <div class="timeline_container">
              <div class="timeline_head">
                <p class="title">グッズ</p>
                <!-- <ul class="menu">
                  <li><a class="focus" href="#">タイムライン</a></li>
                  <li><a href="#">ランキング</a></li>
                </ul> -->
              </div>
              <div class="timeline_body">
                <GoodsAutoMessage
                  :showmessagegoods="showmessagegoods"
                  :rankingarray="rankingarray"
                />
                <!-- <ul class="messageList">
                  <li class="">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="goods">
                        <img src="img/comment_gacha.png" />
                      </div>
                      <div class="name">配信者名</div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="goods">
                        <img src="img/comment_gacha.png" />
                      </div>
                      <div class="name">投稿者名</div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="goods">
                        <img src="img/comment_gacha.png" />
                      </div>
                      <div class="name">投稿者名</div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="goods">
                        <img src="img/comment_gacha.png" />
                      </div>
                      <div class="name">投稿者名</div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="goods">
                        <img src="img/comment_gacha.png" />
                      </div>
                      <div class="name">投稿者名</div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="goods">
                        <img src="img/comment_gacha.png" />
                      </div>
                      <div class="name">投稿者名</div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                </ul> -->
              </div>
            </div>
            <!-- タイムライン_グッズ END -->

            <!-- タイムライン_トレカ  -->
            <div class="timeline_container">
              <div class="timeline_head">
                <p class="title">スーパーコメント</p>
                <!-- <ul class="menu">
                  <li><a class="focus" href="#">タイムライン</a></li>
                  <li><a href="#">ランキング</a></li>
                </ul> -->
              </div>
              <div class="timeline_body">
                <TrecaAutoMessage
                  :showmessagetreca="showmessagetreca"
                  :rankingarray="rankingarray"
                />
                <!-- <ul class="messageList">
                  <li class="card_1">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="card"><img src="img/comment_card.png" /></div>
                      <div class="name">{{ rankingfirstusername }}</div>
                      <div class="cardtype">
                        <img class="star" src="img/comment_star.png" />
                      </div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="card_2">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="card"><img src="img/comment_card.png" /></div>
                      <div class="name">投稿者名</div>
                      <div class="cardtype">
                        <img class="star" src="img/comment_star.png" />
                        <img class="star" src="img/comment_star.png" />
                      </div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="card_3">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="card"><img src="img/comment_card.png" /></div>
                      <div class="name">投稿者名</div>
                      <div class="cardtype">
                        <img class="star" src="img/comment_star.png" />
                        <img class="star" src="img/comment_star.png" />
                        <img class="star" src="img/comment_star.png" />
                      </div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="card_1">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="card"><img src="img/comment_card.png" /></div>
                      <div class="name">投稿者名</div>
                      <div class="cardtype">
                        <img class="star" src="img/comment_star.png" />
                      </div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="card_1">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="card"><img src="img/comment_card.png" /></div>
                      <div class="name">投稿者名</div>
                      <div class="cardtype">
                        <img class="star" src="img/comment_star.png" />
                      </div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                  <li class="card_1">
                    <div class="time">00:00:00</div>
                    <div class="image">
                      <div class="icon"><img src="img/icon_user.png" /></div>
                    </div>
                    <div class="content">
                      <div class="card"><img src="img/comment_card.png" /></div>
                      <div class="name">投稿者名</div>
                      <div class="cardtype">
                        <img class="star" src="img/comment_star.png" />
                      </div>
                      <div class="comment">コメント</div>
                    </div>
                  </li>
                </ul> -->
              </div>
            </div>
            <!-- タイムライン_トレカ END -->

            <!-- ランキング  -->
            <div class="timeline_container">
              <div class="timeline_head">
                <p class="title">ランキング</p>
              </div>

              <div class="timeline_body">
                <ul class="ranking">
                  <li>
                    <div class="icon_conteiner">
                      <div class="icon"><img :src="rankingfirsticonURL" /></div>
                      <div class="rank"><img src="img/rank1.png" /></div>
                    </div>
                    <div class="name">{{ rankingfirstusername }}</div>
                  </li>
                  <li>
                    <div class="icon_conteiner">
                      <div class="icon">
                        <img :src="rankingsecondiconURL" />
                      </div>
                      <div class="rank"><img src="img/rank2.png" /></div>
                    </div>
                    <div class="name">{{ rankingsecondusername }}</div>
                  </li>
                  <li>
                    <div class="icon_conteiner">
                      <div class="icon"><img :src="rankingthirdiconURL" /></div>
                      <div class="rank"><img src="img/rank3.png" /></div>
                    </div>
                    <div class="name">{{ rankingthirdusername }}</div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- ランキング END -->
          </div>
          <!-- timeline_set タイムライン各種 END  -->
        </div>
        <!-- main END -->

        <!-- timeline_main -->

        <div class="timeline_main">
          <div class="timeline_head">
            <p class="title">タイムライン(ALL)</p>
          </div>
          <div class="timeline_body">
            <MainAutoMessage
              :showmessage="showmessage"
              :rankingarray="rankingarray"
            />
          </div>
          <!-- <div class="timeline_body">
            <ul class="messageList">
              <li class="vendor">
                <div class="time">00:00:00</div>
                <div class="image">
                  <div class="icon"><img src="img/icon_user1.jpg" /></div>
                </div>
                <div class="content">
                  <div class="name">配信者名</div>
                  <div class="comment">コメント</div>
                </div>
              </li>
            </ul>
          </div> -->
          <div class="comment_area">
            <input
              class="comment"
              type="text"
              placeholder="コメントする"
              v-model="commenttext"
              @keypress.enter="onKeypressEnter"
            />
          </div>
        </div>
        <!-- timeline_main END -->
      </div>
      <!-- container END -->
    </body>
  </html>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap");

html {
  font-family: "Raleway", sans-serif;
}
body {
  background-color: #000;
}
/*broadcast-----------------------------------------------------------------------------------*/

.container {
  /* width: 100%;
  height: 100%; */
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
}
/* 1 -----------------------------------*/
.main {
  width: 75%;
}
.timeline_main {
  width: 25%;
  background-color: #333;
  border-left: solid 1px #666;
}
/* 1 END-----------------------------------*/
/* 2 -----------------------------------*/
.control_set {
  height: 60%;
  display: flex;
  background-color: #000;
  padding: 5px;
}
.timeline_set {
  height: 40%;
  background-color: #333;
  display: flex;
}
/* 2 END -----------------------------------*/
.btn_back {
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 100;
}
/* movie--------------------------------------------- */
.movie {
  width: 40%;
  overflow: hidden;
  background-color: #222;
  position: relative;
  text-align: center;
}
.movie > div:not(.info) {
  height: 100%;
}
.movie > div > div {
  height: 100%;
}
.movie video {
  height: 100%;
}
.movie .video-js {
  background-color: initial;
  position: inherit;
}
.movie .info {
  width: 100%;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}
.movie .info .connections,
.movie .info .status {
  padding: 10px 20px;
  color: #fff;
  display: flex;
}
.movie .info .connections {
  border-bottom: 1px solid #aaa;
}
.movie .info .status {
  display: flex;
}
.movie .info .title {
  width: 50%;
  font-weight: bold;
  text-align: left;
  line-height: 1.5em;
  font-size: 20px;
}
.movie .info .status .title {
}
.movie .info .count,
.movie .info .time {
  width: 50%;
  text-align: right;
  font-size: 25px;
  line-height: 1;
}
.play {
  color: #00cc44;
}
.stop {
  color: #ff0000;
}

/* control--------------------------------------------- */
.control {
  width: 60%;
  padding: 0px 20px;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #333;
}
.control_unit {
  border-bottom: 1px solid #555;
  padding: 20px 0;
}
a.btn {
  cursor: pointer;
  display: inline-block;
  padding: 7px 20px;
  background: #fff;
  color: #333;
  font-size: 14px;
  border-radius: 2px;
  transition: ease 0.2s;
  border: solid 1px #aaa;
}
a.btn:hover {
  background: #aaa;
}
.control_unit p {
  margin-bottom: 0;
}
dl.info_common {
}
dl.info_common dt {
  float: left;
  width: 140px;
  color: #888;
  margin-bottom: 16px;
  font-size: 12px;
}
dl.info_common dd {
  color: #fff;
  margin-bottom: 16px;
  font-size: 16px;
}
dl.info_common dt:last-child,
dl.info_common dd:last-child {
  margin-bottom: 0;
}

dl.info_broadcast {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
dl.info_broadcast dt {
  width: 140px;
  color: #888;
  font-size: 12px;
  margin-bottom: 20px;
}
dl.info_broadcast dd {
  width: calc(100% - 140px);
  color: #fff;
  font-size: 14px;
  word-wrap: break-word;
  margin-bottom: 20px;
}
dl.info_broadcast dt:last-of-type {
  margin-bottom: 0;
}
dl.info_broadcast dt .btn {
  margin-top: 5px;
}

ul.btn_set {
  display: flex;
  font-size: 14px;
  color: #fff;
}
ul.btn_set li {
  margin-right: 50px;
  text-align: center;
  line-height: 1.5em;
  list-style: none;
}
ul.btn_set li:last-child {
  margin-right: 0;
}

.control_unit a.movie_play,
.control_unit a.movie_stop {
  cursor: pointer;
  display: inline-block;
  background: #fff;
  color: #333;
  border-radius: 2px;
  transition: ease 0.2s;
  border: solid 1px #aaa;
  font-size: 16px;
  padding: 15px 20px 15px 50px;
  margin-bottom: 10px;
  position: relative;
}
.control_unit a.movie_play:hover,
.control_unit a.movie_stop:hover {
  background: #aaa;
}
.control_unit a.movie_play::before {
  content: "";
  display: inline-block;
  border-style: solid;
  border: 20px solid;
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-color: transparent transparent transparent #00cc44;
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.control_unit a.movie_stop::before {
  content: "";
  display: inline-block;
  border-style: solid;

  border: 10px solid #ff0000;

  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

/* timeline_set--------------------------------------------- */

.timeline_container {
  width: calc(100% / 3);
  border-left: solid 1px #666;
}

.timeline_head {
  background-color: #444444;
  height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.timeline_head .title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  height: 30px;
  width: 100%;
  padding-top: 5px;
}
.timeline_head ul.menu {
  display: flex;
  width: 100%;
}
.timeline_head ul.menu li {
  width: 50%;
  height: 35px;
  text-align: center;
  font-size: 14px;
}
.timeline_head ul.menu li a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  padding-top: 0.5em;
  transition: ease 0.2s;
  background: #444;
}
.timeline_head ul.menu li a:hover {
  background: #333;
}
.timeline_head ul.menu li a.focus {
  border-bottom: solid 3px #fff;
}

/* timeline_main--------------------------------------------- */
.timeline_main {
  position: relative;
}

.comment_area {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}
.comment_area .comment {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  padding: 0 10px;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
}
.comment_area .comment:focus {
  border: 0;
}

.timeline_body {
  overflow-y: scroll;
  height: calc(100% - 50px);
  padding: 10px;
}
.timeline_main .timeline_body {
  padding-bottom: 70px;
}

/*ranking*/
ul.ranking {
}
ul.ranking li {
  /*border-bottom: solid 1px #555;*/
  margin-bottom: 15px;
  display: flex;
}
ul.ranking .icon_conteiner {
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-bottom: 10px;
}
ul.ranking .name {
  margin-top: 15px;
  color: #fff;
}
ul.ranking li .icon {
  border-radius: 100px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
ul.ranking li .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul.ranking li .rank {
  position: absolute;
  display: inline-block;
  top: 27px;
  right: 0px;
  width: 15px;
  height: 20px;
}
ul.ranking li .rank img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*comment--------------------------------------------- */

ul.messageList {
}
ul.messageList li {
  display: flex;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 5px 10px;
  margin-left: 65px;
  margin-bottom: 10px;
}
ul.messageList li.vendor {
  background: rgba(28, 221, 255, 0.9);
}
ul.messageList li.rank_1 {
  border: 3px solid rgba(255, 179, 0, 0.9);
}
ul.messageList li.rank_2 {
  border: 3px solid rgba(100, 100, 100, 0.9);
}
ul.messageList li.rank_3 {
  border: 3px solid rgba(175, 88, 0, 0.9);
}
ul.messageList li.card_1,
ul.messageList li.card_2,
ul.messageList li.card_3 {
  background: rgba(255, 87, 87, 0.9);
}
ul.messageList li .time {
  position: absolute;
  top: 0;
  left: -65px;
  color: #aaa;
  font-size: 12px;
}
ul.messageList li .image {
  position: relative;
  margin-right: 10px;
}
ul.messageList li .icon {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  overflow: hidden;
}
ul.messageList li .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul.messageList li .image .rank {
  position: absolute;
  display: inline-block;
  top: 17px;
  right: -5px;
  width: 15px;
  height: 17px;
  color: #888;
}
ul.messageList li .image .rank img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
ul.messageList li .content {
  flex: 1;
  padding: 0.4em 0;
  text-align: left;
}
ul.messageList li .content .name {
  display: inline;
  font-size: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #888;
}
ul.messageList li.card_1 .content .name,
ul.messageList li.card_2 .content .name,
ul.messageList li.card_3 .content .name,
ul.messageList li.vendor .content .name {
  color: rgba(255, 255, 255, 0.7);
}
ul.messageList li.card_1 .content .comment,
ul.messageList li.card_2 .content .comment,
ul.messageList li.card_3 .content .comment,
ul.messageList li.vendor .content .comment {
  color: #fff;
}
ul.messageList li .content .cardtype {
  display: inline-block;
  border-radius: 20px;
  background-color: #fff;
  padding: 0 8px 2px 8px;
  margin-right: 5px;
}
ul.messageList li .content .cardtype img.star {
  width: 14px;
  height: 15px;
  margin-right: 0;
}
ul.messageList li .content .comment {
  display: inline;
  font-size: 14px;
  line-height: 1.2em;
}
ul.messageList li .goods,
ul.messageList li .card {
  position: absolute;
  top: -3px;
  left: -7px;
  /*display: inline-block;*/
  width: 27px;
  height: 27px;
  background-size: cover;
  background-color: #bbbbbb;
  border-radius: 100px;
  border: solid 2px #ffffff;
}
ul.messageList li .goods img,
ul.messageList li .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .message-container {
  max-height: 400px;
  overflow-y: auto;
} */

.textaline_center {
  text-align: center;
}
.textaline_left {
  text-align: left;
}
.textaline_right {
  text-align: right;
}
.margin_bottom_reset {
  margin-bottom: 0 !important;
}
</style>
